import { Box, IconButton, Typography, useTheme } from '@mui/material'
import * as React from 'react'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { styled } from '@mui/material/styles'
import { v4 as uuidv4 } from 'uuid'
import { uploadFile } from '../../api/files'
import { uploadImageFile } from '../../utils/files'
import createAlertSnackbar from '../modals/snackbars'
import CircularProgress from '@mui/material/CircularProgress'

const Input = styled('input')({
    display: 'none',
})

export default function FileUpload({
    onUploadedDocumentSuccess,
    uploadFilePath,
    acceptFileFomarts = 'image/png, image/jpeg, image/jpg, application/pdf',
}: {
    onUploadedDocumentSuccess: (input: any) => Promise<void>
    uploadFilePath: string
    acceptFileFomarts?: string
}) {
    const [beganUpload, setBeganUpload] = React.useState<boolean>(false)
    const [imageLoading, setImageLoading] = React.useState<boolean>(false)
    const [uploadProgress, setUploadProgress] = React.useState<number>(0)
    const theme = useTheme()
    const uploadBoxId = uuidv4()
    const { openAlert, AlertComponent } = createAlertSnackbar('error')

    function setUploadImageProgress(uploadPrgoress: number) {
        setUploadProgress(Math.round(uploadPrgoress / (4 / 3)))
    }

    function getFileFromEvent(event: any) {
        if (event.target.files.length !== 1) {
            throw new Error('only 1 file is acceptable')
        }
        return event.target.files[0]
    }

    function onUploadFileError(error: Error) {
        setImageLoading(false)
        openAlert(error.toString())
    }

    async function handleUpload(event: React.ChangeEvent<HTMLInputElement>) {
        setImageLoading(true)
        setBeganUpload(true)
        setUploadProgress(0)
        try {
            const fileToUpload = getFileFromEvent(event)
            const onFormDataReady = async (formData: FormData) => {
                try {
                    const result = await uploadFile(
                        uploadFilePath,
                        formData,
                        setUploadImageProgress
                    )
                    await onUploadedDocumentSuccess(result)
                    setUploadProgress(100)
                } catch (error) {
                    return
                } finally {
                    setImageLoading(false)
                }
            }
            uploadImageFile(fileToUpload, onFormDataReady, onUploadFileError)
        } catch (error) {
            setImageLoading(false)
            setUploadProgress(0)
        }
    }

    const progressRender = (
        <Box>
            <CircularProgress sx={{ m: 'auto' }} />
            <Typography sx={{ textAlign: 'center' }}>{Math.round(uploadProgress)} %</Typography>
        </Box>
    )

    function ButtonWrapper({ children }: any) {
        return (
            <Box
                sx={{
                    height: '120px',
                    alignSelf: 'stretch',
                    flexGrow: '0',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',
                    gap: '4px',
                    padding: '24px',
                    borderRadius: '12px',
                    border: 'solid 1px #eaecf0',
                    backgroundColor: '#fff',
                    cursor: 'pointer',
                }}
            >
                <Box
                    sx={{
                        height: '72px',
                        alignSelf: 'stretch',
                        flexGrow: '0',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '12px',
                        padding: '0',
                    }}
                >
                    {children}
                </Box>
            </Box>
        )
    }

    function renderUploadButton() {
        return (
            <label htmlFor={`button-file-upload-${uploadBoxId}`} datacy="uploadImage">
                <Input
                    onChange={handleUpload}
                    accept={acceptFileFomarts}
                    id={`button-file-upload-${uploadBoxId}`}
                    type="file"
                />
                <IconButton
                    component="span"
                    sx={{
                        borderRadius: '12px',
                    }}
                    aria-label="add photo"
                >
                    <ButtonWrapper>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '40px',
                                height: '40px',
                                flexGrow: '0',
                                padding: '10px',
                                borderRadius: '28px',
                                border: `solid 6px lightgray`,
                                backgroundColor: 'lightgray',
                            }}
                        >
                            <FileUploadIcon
                                sx={{
                                    width: '20px',
                                    height: '20px',
                                }}
                            />
                        </Box>
                        <Typography
                            sx={{
                                width: '220px',
                                height: '20px',
                                flexGrow: '0',
                                fontSize: '14px',
                                fontWeight: '500',
                                fontStretch: 'normal',
                                fontStyle: 'normal',
                                lineHeight: '1.43',
                                letterSpacing: 'normal',
                                textAlign: 'center',
                                color: theme.palette.primary.main,
                            }}
                        >
                            Klik for at uploade fil
                        </Typography>
                    </ButtonWrapper>
                </IconButton>
            </label>
        )
    }

    if (imageLoading || beganUpload) {
        return (
            <>
                {AlertComponent}
                {progressRender}
            </>
        )
    } else {
        return (
            <>
                {AlertComponent}
                {renderUploadButton()}
            </>
        )
    }
}
