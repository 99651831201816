import * as React from 'react'
import { Box, Icon, TextField, Menu, MenuItem, Typography, Divider } from '@mui/material'
import createAlertSnackbar from '../modals/snackbars'
import CircularProgress from '@mui/material/CircularProgress'
import DocumentHash from './DocumentHash'
import FileUpload from '../input/FileUpload'
import DynamicLoadingButton from '../input/DynamicLoadingButton'

export default function ({ locationId }: { locationId?: string }) {
    const [loading, setLoading] = React.useState<boolean>(true)
    const [uploadedDocumentHash, setUploadedDocumentHash] = React.useState<string | null>(null)
    const [originalDocumentHash, setOriginalDocumentHash] = React.useState<string | null>(null)
    const { openAlert, AlertComponent } = createAlertSnackbar('warning', 2)

    const hashes_match =
        !!uploadedDocumentHash && !!originalDocumentHash
            ? uploadedDocumentHash === originalDocumentHash
                ? 1
                : 0
            : -1

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'min(600px, 100%)',
                margin: 'auto',
                pt: '30px',
                px: '8px',
            }}
        >
            <Typography textAlign="center" component="span" variant="h5" fontWeight="bold">
                GeoSnapshot dokument verificering ved ll33a.dk's checksum-verification service
                <br />
                <br />
            </Typography>

            <DocumentHash
                onHashAvailable={setOriginalDocumentHash}
                initialLocationId={locationId}
            />
            <br />
            <br />
            <Typography fontWeight="bold">Upload GeoSnapshot pdf-dokument til kontrol</Typography>
            {uploadedDocumentHash ? (
                <>
                    <DynamicLoadingButton
                        size="small"
                        variant="outlined"
                        onClick={() => setUploadedDocumentHash(null)}
                    >
                        Nyt dokument
                    </DynamicLoadingButton>
                    <Typography>
                        Hashværdi kontrolsum for det uploadede dokument:
                        <Typography fontWeight="bold" textAlign="center">
                            {uploadedDocumentHash}
                        </Typography>
                    </Typography>
                </>
            ) : (
                <FileUpload
                    onUploadedDocumentSuccess={(response) => {
                        if (!response?.provided_file_hash) return
                        setUploadedDocumentHash(response?.provided_file_hash)
                    }}
                    acceptFileFomarts="application/pdf"
                    uploadFilePath={`/locations/${locationId}/statements/compare-hashes`}
                />
            )}
            <br />
            <br />
            {hashes_match > -1 && (
                <Typography
                    fontWeight="bold"
                    textAlign="center"
                    sx={{
                        color: hashes_match === 1 ? '#7EAC55' : '#f5222d',
                    }}
                >
                    {hashes_match === 1 && 'Hash værdierne matcher, dokumentet er intakt'}
                    {hashes_match === 0 &&
                        'Hash værdierne matcher ikke, dokumentet kan ikke genkendes og bør undersøges nærmere.'}
                </Typography>
            )}
            <br />
            <br />
            <Box sx={{ width: '100%', height: '2px', background: 'black' }} />
            <br />
            <br />

            <Box>
                <Typography fontWeight="bold">
                    Med ll33a.dk's checksum-verification service kan du få bekræftet om et
                    GeoSnapshot dokument er intakt ved hjælp af SHA256 Hash Verificering.
                </Typography>
                <br />
                <Typography fontWeight="bold">SHA256 Hash Verificering</Typography>
                <br />
                <Typography>
                    <Typography component="span" fontWeight="bold">
                        Verificering af GeoSnapshot Dokumentets Integritet med SHA256
                    </Typography>
                    <br />
                    For at sikre og objektivt bekræfte integriteten af det GeoSnapshot dokument, du
                    har modtaget, benytter vi os af SHA256, en anerkendt og stærk hash-algoritme.
                    Ved at sammenligne kontrol hashværdien med hashværdien af det udleverede
                    dokument, kan det afgøres om dokumentet er intakt.
                    <br />
                    <br />
                    <Typography component="span" fontWeight="bold">
                        Hvad er SHA256?
                    </Typography>
                    <br />
                    SHA256 (Secure Hash Algorithm 256-bit) er en kryptografisk hashfunktion, der
                    omdanner en indtastning (i dette tilfælde et GeoSnapshot dokument) til en unik
                    256-bit (32-byte) hashværdi. Selv den mindste ændring i dokumentet vil resultere
                    i en fuldstændig anderledes hashværdi, hvilket gør det ekstremt svært at
                    manipulere dokumentets indhold uden at blive opdaget.
                    <br />
                    <br />
                    <Typography component="span" fontWeight="bold">
                        Ethvert GeoSnapshot oprettes med en tilhørende hashværdi til kontrol.
                    </Typography>
                    <br />
                    Ved en GeoSnapshot-session, bliver der efterfølgende genereret en hashværdi af
                    det auto-genereret pdf dokument. Denne hashværdi arkiveres i ll33a.dk's database
                    og kan herefter rekvireres ved behov.
                    <br />
                    <br />
                    <Typography component="span" fontWeight="bold">
                        Sammenlign hashværdi
                    </Typography>
                    <br />
                    Til dette formål indeholder hvert GeoSnapshot et link til ll33a.dk's
                    checksum-verification service, hvor hashværdien af det oprindelige GeoSnapshot
                    dokument vises. Ved at uploade det udleverede GeoSnapshot dokument til
                    ll33a.dk's checksum-verification service, genereres en hashværdi, som
                    sammenlignes med kontrol hasværdien. Alternativt kan du med dit eget SHA256
                    værktøj, selv generere en hashværdi af det udleverede GeoSnapshot dokument, for
                    herefter at indsætte denne og sammenligne med dokumentets kontrol hashværdi fra
                    ll33a.dk's checksum-verification service. Hermed kan du på sikker vis nemt og
                    hurtigt efterprøve dokumentet's autencitet.
                    <br />
                    <br />
                    <br />
                    <Typography fontWeight="bold" component="span">
                        Hvordan verificerer jeg GeoSnapshot dokumentet?
                    </Typography>
                    <br />
                    <br />
                    <Typography fontWeight="bold" component="span">
                        1. Få en SHA256 Hash Værdi af Dit Dokument du ønsker verificeret:
                    </Typography>{' '}
                    Brug et pålideligt SHA256 hashværktøj til at generere en hashværdi af det
                    modtagne dokument. Alternativt kan du benytte ll33a.dk's checksum-verification
                    service til generere en SHA256 hashværdi.
                    <br />
                    <Typography fontWeight="bold" component="span">
                        2. Sammenlign Hash Værdier:
                    </Typography>{' '}
                    Sammenlign den genererede hashværdi med den oprindelige hashværdi, der er
                    leveret af ll33a.dk's checksum-verification service.
                    <br />
                    <Typography fontWeight="bold" component="span">
                        3. Overensstemmelse:
                    </Typography>{' '}
                    Hvis de to hashværdier matcher, kan du være sikker på, at dokumentet ikke er
                    blevet ændret eller manipuleret, siden det blev oprettet på grundlag af
                    GeoSnapshot-sessionen.
                    <br />
                    <Typography fontWeight="bold" component="span">
                        4. Ikke-Overensstemmelse:
                    </Typography>{' '}
                    Hvis hashværdierne ikke matcher, kan det tyde på, at dokumentets indhold er
                    blevet ændret, og det kan kræve yderligere undersøgelse.
                    <br />
                    <br />
                    <br />
                    Fordele ved at Bruge SHA256:
                    <br />
                    <br />
                    <Typography fontWeight="bold" component="span">
                        - Sikkerhed:
                    </Typography>{' '}
                    SHA256 er bredt anerkendt for sin evne til at skabe unikke og sikre hashværdier.
                    <br />
                    <Typography fontWeight="bold" component="span">
                        - Objektivitet:
                    </Typography>{' '}
                    Hashværdien afhænger udelukkende af dokumentets indhold, hvilket sikrer en
                    objektiv metode til verificering.
                    <br />
                    <Typography fontWeight="bold" component="span">
                        - Enkelhed:
                    </Typography>{' '}
                    Processen er enkel og kan udføres med en række gratis tilgængelige værktøjer.
                    <br />
                    <br />
                    Ved at følge disse trin kan du være sikker på dokumentets autenticitet og
                    integritet, hvilket understøtter et transparent og pålideligt grundlag for
                    verificering.
                </Typography>
            </Box>
        </Box>
    )
}
