import * as React from 'react'
import LocationDocuments from '../../components/Location/LocationDocuments'
import { Box } from '@mui/material'
import { navigate } from 'gatsby'
import Container from '../../components/Landing/Container'
import LocationDocumentVerificationView from '../../components/Location/LocationDocumentVerificationView'

export default function (props) {
    return (
        <Container setCookieValue={() => {}} pathName="location-verification-specific">
            <LocationDocumentVerificationView locationId={props.params.locationId} />
        </Container>
    )
}
