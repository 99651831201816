import * as React from 'react'
import { Box, Icon, TextField, Menu, MenuItem, Typography } from '@mui/material'
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded'
import DynamicLoadingButton from '../input/DynamicLoadingButton'
import Webcam from 'react-webcam'
import CameraswitchRoundedIcon from '@mui/icons-material/CameraswitchRounded'
import CameraIcon from '@mui/icons-material/Camera'
import { uploadImageFile } from '../../utils/files'
import CancelIcon from '@mui/icons-material/Cancel'
import { uploadFile } from '../../api/files'
import createAlertSnackbar from '../modals/snackbars'
import CircularProgress from '@mui/material/CircularProgress'
import useInterval from '../../services/useInterval'
import { httpGet } from '../../services/http'

export default function ({
    initialLocationId,
    onHashAvailable = (i) => {},
}: {
    onHashAvailable: (hash: string) => void
    initialLocationId?: string
}) {
    const [loading, setLoading] = React.useState<boolean>(true)
    const [documentHash, setDocumentHash] = React.useState<string | null>(null)
    const [locationId, setLocationId] = React.useState<string | null>(null)
    const { openAlert, AlertComponent } = createAlertSnackbar('warning', 2)

    React.useEffect(() => {
        if (initialLocationId != null) {
            setLocationId(initialLocationId)
        }
    }, [initialLocationId])

    async function loadHash() {
        setDocumentHash(null)
        const response = await httpGet<{ original_hash: string }>(
            `/locations/${locationId}/statements/compare-hashes`
        )
        const originalHash = response?.data?.original_hash
        if (originalHash) {
            setDocumentHash(originalHash)
            onHashAvailable(originalHash)
        } else {
            openAlert('Something went wrong', 'error')
        }
    }

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
            }}
        >
            {AlertComponent}
            <TextField
                label="GeoSnapshot ID"
                fullWidth
                value={locationId}
                onChange={(event) => {
                    setDocumentHash(null)
                    setLocationId(event.target.value)
                }}
            />
            <DynamicLoadingButton
                sx={{ margin: null }}
                variant="contained"
                disabled={!!documentHash}
                color="primary"
                onClick={loadHash}
            >
                Beregn hash værdi
            </DynamicLoadingButton>
            {!!documentHash && (
                <Typography>
                    Hashværdi kontrolsum for det originale dokument:
                    <Typography fontWeight="bold" textAlign="center">
                        {documentHash}
                    </Typography>
                </Typography>
            )}
        </Box>
    )
}
